import {useEffect, Fragment} from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import {CacheProvider} from '@emotion/react';
import {Provider} from 'react-redux';

import {ThemeProvider} from '@jobseeker/lotus/lib/helpers';
import {gaTrackPageView} from '@jobseeker/common-core/lib/utils/gtag';

import {SnackbarProvider} from 'notistack';
import {wrapper} from '@jobseeker/common-core/lib/stores';

import {createEmotionCache} from '@helpers';
import {APP_NAME_IIMJOBS, THEME} from '@constants';
import {metaInfo} from '@configs';

import type {AppProps} from 'next/app';
import type {EmotionCache} from '@emotion/react';
import '../styles/common.css';

import {MaterialDesignContent} from 'notistack';
import {styled} from '@mui/material';
import Head from 'next/head';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#49966E',
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#F46C56',
  },
}));

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

interface WebAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const WebApp = (props: WebAppProps) => {
  const {Component, emotionCache = clientSideEmotionCache, pageProps} = props;

  const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME || APP_NAME_IIMJOBS;

  const metaData = metaInfo[APP_NAME];

  const theme = metaData[THEME];

  const {store} = wrapper.useWrappedStore(pageProps);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gaTrackPageView(url, metaData['gaTagId']);
    };
    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  return (
    <Fragment>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        ></meta>
      </Head>
      <CacheProvider value={emotionCache}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />
            <SnackbarProvider
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent,
              }}
              maxSnack={4}
            />
          </ThemeProvider>
        </Provider>
      </CacheProvider>
    </Fragment>
  );
};

WebApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};

export default WebApp;

import axios from 'axios';
import {CATEGORY, JOBDETAIL, SEARCH, TAG} from '@constants';
import * as https from 'https';

const seoKeys = ['title', 'description', 'keywords'];

const urlKeys = ['canonical', 'url'];

const paginationKeys = ['prev', 'next'];

/**
 * TODO: Optimise this function for performance and unknown keys, currently it's too much related to SEO only.
 */
const searchReplaceSeoConfig = (
  targetObj: {
    [key: string]: /* eslint-disable @typescript-eslint/no-explicit-any */ any;
  },
  title: string,
  description: string,
  slugUrl: string,
  page: string,
  pageNo: number
) => {
  if (typeof targetObj === 'object') {
    for (const key in targetObj) {
      if (typeof targetObj[key] === 'object' && key !== 'images') {
        searchReplaceSeoConfig(
          targetObj[key],
          title,
          description,
          slugUrl,
          page,
          pageNo
        );
      } else {
        if (seoKeys.includes(key)) {
          if (page === SEARCH) {
            if (targetObj[key].includes('{{slug}}')) {
              targetObj[key] =
                pageNo > 1 && key === 'title'
                  ? `Page-${pageNo} for ${targetObj[key]
                      ?.split('{{slug}}')
                      .join(title)}`
                  : targetObj[key]?.split('{{slug}}').join(title);
            }
          } else if (
            [JOBDETAIL, CATEGORY, TAG].includes(page) &&
            key === 'title'
          ) {
            targetObj[key] = `${title} ${targetObj[key]}`;
          } else if (key === 'description') {
            targetObj[key] =
              pageNo > 1
                ? `${description} ${targetObj[key]} Page-${pageNo}`
                : `${description} ${targetObj[key]}`;
          } else {
            targetObj[key] = `${title} ${
              targetObj[key] === 'title' ? '' : ','
            } ${targetObj[key]}`;
          }
        }

        if (urlKeys.includes(key)) {
          targetObj[key] = `${targetObj[key]}${slugUrl}`;
        }

        if (
          (key === 'property' && targetObj[key] === 'og:headline') ||
          ['og:description', 'prev', 'next'].includes(targetObj[key])
        ) {
          if (page === SEARCH) {
            if (targetObj[key].includes('{{slug}}')) {
              targetObj[key] = targetObj[key]?.split('{{slug}}').join(title);
            }
          }
          if (targetObj[key] === 'og:description') {
            targetObj['content'] =
              pageNo > 1
                ? `${description} ${targetObj['content']} Page-${pageNo}`
                : `${description} ${targetObj['content']}`;
          } else if (paginationKeys.includes(targetObj[key])) {
            if (pageNo > 1) {
              if (targetObj[key] === 'prev') {
                targetObj['href'] =
                  pageNo - 1 <= 1
                    ? `${targetObj['href']}${slugUrl}`
                    : `${targetObj['href']}${slugUrl?.split('?')[0]}?page=${
                        pageNo - 1
                      }`;
              } else {
                targetObj['href'] = `${targetObj['href']}${slugUrl?.split(
                  '?'
                )[0]}?page=${pageNo + 1}`;
              }
            } else if (pageNo <= 1) {
              if (targetObj[key] === 'next') {
                targetObj['href'] = `${targetObj['href']}${slugUrl?.split(
                  '?'
                )[0]}?page=2`;
              } else {
                delete targetObj['href'];
              }
            }
          } else {
            targetObj['content'] = `${title} ,${targetObj['content']}`;
          }
        }
      }
    }
  }
  return targetObj;
};

const loadSEO = async (
  page: string,
  content?: {title: string; description: string},
  slugUrl?: string,
  isCustom?: boolean,
  pageNo = 0
) => {
  if (page !== '/') {
    const httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    });
    const response = await axios.get(
      `${process.env.NEXT_PUBLIC_LOAD_SEO}/data/seo/${page}.json`,
      {httpsAgent}
    );

    if (isCustom && content?.title && slugUrl) {
      const responseInfo = searchReplaceSeoConfig(
        response?.data,
        content?.title,
        content?.description,
        slugUrl,
        page,
        pageNo
      );

      return responseInfo;
    }

    return response.data;
  }

  return;
};

export default loadSEO;

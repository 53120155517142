/* eslint-disable @typescript-eslint/no-explicit-any */
import {colorPalette, mainColor} from '@jobseeker/lotus/lib/foundations';

import type {ThemeOptions} from '@mui/material/styles';

const theme: Partial<ThemeOptions> | any = {
  palette: {
    primary: {
      main: colorPalette.green['A500'],
      contrastText: colorPalette.white['A50'],
      light: colorPalette.green['A600'],
    },
    secondary: {
      main: colorPalette.black['A600'],
      contrastText: colorPalette.white['A50'],
    },
    text: {
      primary: mainColor.black,
      secondary: colorPalette.black['A100'],
      disabled: colorPalette.white['A125'],
    },
    background: {
      default: mainColor.white,
      secondary: colorPalette.green['A601'],
    },
    info: {
      main: colorPalette.dustGreen['A400'],
    },
    error: {
      main: colorPalette.error['A500'],
      dark: colorPalette.error['A600'],
    },
    warning: {
      main: colorPalette.warning['A300'],
    },
    success: {
      main: colorPalette.success['A300'],
    },
    prompt: {
      main: colorPalette.prompt['A100'],
      dark: colorPalette.prompt['A200'],
      background: colorPalette.prompt['A300'],
    },
    gray: {
      light: colorPalette.white['A200'],
    },
  },
};

export default theme;

import theme from '@styles';
import {app} from './app';

export type MetaElement = {
  siteName: string;
  locale: string;
  gaTagId: string;
  gaManagerId: string;
  theme: object;
  app: {
    [key: string]: object;
  };
};

export interface MetaType<TValue> {
  [key: string]: TValue;
}

export const metaInfo: MetaType<MetaElement> = {
  iimjobs: {
    siteName: 'iimjobs.com',
    locale: 'en',
    gaTagId: 'UA-2175565-2',
    gaManagerId: 'GTM-5JSBZG7',
    theme: theme['iimjobs'],
    app: app['iimjobs'],
  },
};
